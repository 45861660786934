.intro {
    display: none;
    background-color: rgba(#fbf9f6, .8);
    position: absolute;
    top: 117px;
    left: 30px;
    max-width: 578px;
    width: 100%;
    z-index: 1;
    padding: 49px 26px 48px;

    &--active {
        display: block;
        // display: none;
    }

    &__title {
        font-family: Ambit;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        margin: 0 0 30px;
    }

    &__description {
        font-family: TUIType;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.12px;
        margin: 0;
    }
}
