.project-detail-slider {
    &__image-container {
        height: 190px;
        margin: 0;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }

    &__video-container {
        height: 190px;
        margin: 0;
        cursor: pointer;
        position: relative;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.35);
            pointer-events: all;
        }
        &::after {
            content: "";
            mask-image: url("../../../public/assets/icons/icon-play.svg");
            mask-repeat: no-repeat;
            background-color: white;
            position: absolute;
            z-index: 2;
            height: 95px;
            width: 88px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.is-playing::after {
            mask-image: url("../../../public/assets/icons/icon-pause.svg");
        }
    }

    &.one-slide {
        .swiper-wrapper {
            justify-content: center;
        }
    }

    &.two-slides {
        .swiper-wrapper {
            justify-content: center;

            .swiper-slide:nth-child(2) {
                margin-right: 0 !important;
            }
        }
    }

    .swiper {
        position: static;
    }

    // Pagination
    .swiper-pagination {
        bottom: 30px;
    }

    .swiper-pagination-bullet {
        background: #2e3127;
        opacity: 0.5;
        width: 11px;
        height: 11px;
    }

    .swiper-pagination-bullet-active {
        background: #2e3127;
        opacity: 1;
    }
    // End Paginantion

    // Navigation
    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 47px;
        height: 47px;
    }

    .swiper-button-prev {
        background-image: url("/assets/icons/icon-arrow.svg");
        transform: rotate(180deg);
        left: 30px;
    }

    .swiper-button-next {
        background-image: url("/assets/icons/icon-arrow.svg");
        right: 30px;
    }
    // End Navigation
}
