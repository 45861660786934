.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0.59) 71%, rgba(255, 255, 255, 0));

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 117px;
    }

    &__logo-container {
        max-width: 469px;
        max-height: 98px;
        width: 100%;
        height: 98px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
