.category-list {
    padding: 0;
    margin: 0;
    display: none;
    gap: 0 10px;

    &--active {
        display: flex;
    }
}
