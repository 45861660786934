.project-item {
    display: flex;
    gap: 0 10px;
    cursor: pointer;

    &__img {
        max-width: 174px;
        width: 100%;
        height: 101px;
        object-fit: cover;
        pointer-events: none;
    }

    &__name {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: -0.12px;
        pointer-events: none;
        margin: 0;
        color: #1b115c;
        max-width: 196px;
        width: 100%;
    }

    &--selected {
        border: 1px solid black;
    }
}
