.marker {
    width: 2%;
    position: absolute;
    transform-origin: bottom center;
    cursor: pointer;

    &__wrapper {
        pointer-events: none;
    }

    svg {
        animation: pulse 1800ms infinite ease-in-out;
    }

    // Remove marker number
    &.move-marker {
        transition: 250ms;

        [data-numbers] {
            &::after {
                content: "";
            }
        }
    }

    // Marker Image
    &.marker-image {
        width: auto;
    }
    &__image-container {
        margin: 0;
        width: 87px;
        height: 87px;
        border-radius: 50%;
        overflow: hidden;
        animation: pulseImage 1800ms infinite ease-in-out;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
@for $var from 0 to 4 {
    .marker.cat-#{$var} {
        svg {
            animation-delay: calc(#{-$var} / 3 * 1s);
        }
    }
}
@keyframes pulse {
    0% {
        transform: scale(0.93);
    }

    70% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(0.93);
    }
}

@keyframes pulseImage {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}

.marker.cat-1 {
    svg {
        pointer-events: none;
    }

    .marker-color {
        fill: #b5be5a;
    }
}

.marker.cat-2 {
    svg {
        pointer-events: none;

        .marker-color {
            fill: #30b675;
        }
    }
}

.marker.cat-3 {
    svg {
        pointer-events: none;

        .marker-color {
            fill: #f39300;
        }
    }
}

.marker.cat-4 {
    svg {
        pointer-events: none;

        .marker-color {
            fill: #3567f6;
        }
    }
}
.marker.cat-5 {
    svg {
        pointer-events: none;

        .marker-color {
            fill: #C5E4CD;
        }
    }
}

[data-numbers] {
    position: relative;
    &::after {
        content: attr(data-numbers);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%);
        font-family: TUIType;
        font-size: 20px;
        color: white;
    }
}
