@mixin categoryStyle($color) {
    background-color: white;
    border: 5px solid $color;
    color: black;
    transition: all 350ms;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: $color;
        border: 5px solid $color;
        color: white;
    }
}

.category-item {
    width: 275px;
    padding: 23px;
    text-align: center;
    list-style-type: none;
    border-radius: 5px;
    background-color: lightgrey;

    font-family: Ambit;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.12px;

    &__name {
        margin: 0;
        pointer-events: none;
    }

    &#cat-1 {
        @include categoryStyle(#b5be5a);
    }

    &#cat-2 {
        @include categoryStyle(#30b675);
    }

    &#cat-3 {
        @include categoryStyle(#f39300);
    }

    &#cat-4 {
        @include categoryStyle(#3567f6);
    }
    &#cat-5 {
        @include categoryStyle(#C5E4CD);
    }

    &--active#cat-1 {
        background-color: #b5be5a;
        color: white;
    }

    &--active#cat-2 {
        background-color: #30b675;
        color: white;
    }

    &--active#cat-3 {
        background-color: #f39300;
        color: white;
    }

    &--active#cat-4 {
        background-color: #3567f6;
        color: white;
    }
}
