@mixin disableZoomBtn() {
    &.disabled {
        &::before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: white;
            z-index: 2;
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
}

.zoom {
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 1;

    button {
        cursor: pointer;
        width: 68px;
        height: 68px;
        display: block;
        position: relative;
        padding: 0;
        border: 0;
        background-color: transparent;
    }

    &--in {
        &::after {
            content: "";
            background-image: url("/assets/icons/icon-zoom-plus.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @include disableZoomBtn();
    }

    &--out {
        &::after {
            content: "";
            background-image: url("/assets/icons/icon-zoom-minus.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @include disableZoomBtn();
    }
}
