.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    padding: 20px 30px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 27%);

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
