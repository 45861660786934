.more-projects {
    // transform: translateY(200%);
    // will-change: transform;
    // animation: showMoreProjects 350ms forwards cubic-bezier(0, 0, 0.3, 1);

    @keyframes showMoreProjects {
        0% {
            transform: translateY(200%);
        }
        100% {
            transform: translateY(0);
        }
    }

    &__title {
        font-family: Ambit;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.17px;
        margin: 0 0 25px;
    }

    &__projects {
        display: flex;
    }

    .project-item {
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
}
