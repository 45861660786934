.language-switcher {
    display: none;
    margin-right: 30px;

    &--active {
        display: inline-block;
    }

    &__separator {
        font-family: Ambit;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.17px;
    }

    &__button {
        cursor: pointer;
        background: transparent;
        border: none;
        padding: 0;

        font-family: Ambit;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.17px;
    }
}
