.map {
    overflow: hidden;

    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        position: relative;
        transition: transform 450ms ease;
        transform-origin: center;
    }

    svg {
        width: 100%;
        height: auto;
        // height: 100%;
        fill: #d7d2c3;
        stroke: white;
        stroke-width: 0.5;
    }
}
