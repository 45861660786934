.project-detail {
    position: absolute;
    top: 25%;
    left: 33.5%;
    z-index: 1;
    max-width: 1097px;
    width: 100%;

    background-color: white;
    box-shadow: 2px 6px 16px 0 rgba(179, 175, 162, 0.73);

    &--hide {
        display: none;
    }

    &__wrapper {
        position: relative;
        padding: 57px 112px 77px;

        &::after {
            content: "";
            background-image: url("/assets/backgrounds/project-detail-bg.png");
            background-repeat: no-repeat;
            display: inline-block;
            width: 100%;
            height: 124px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }

    &__close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &::after {
            content: "";
            background-image: url("/assets/icons/icon-close.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 47px;
            height: 47px;
        }
    }

    &__title {
        font-family: Ambit;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.19px;
        text-align: center;
        color: #1b115c;
        margin: 0 0 42px;
    }

    &__description {
        font-family: Ambit;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.1px;
        text-align: center;
        margin: 30px 0 0;
    }
}
