.project-list {
    position: absolute;
    top: 15%;
    left: 0;
    z-index: 1;
    width: 464px;
    height: 612px;
    // transform: translateX(-100%);
    box-shadow: 2px 6px 16px 0 rgba(179, 175, 162, 0.73);
    // will-change: transform;
    // animation: showProjectList 250ms forwards cubic-bezier(0, 0, 0.3, 1);
    // animation: showProjectList 350ms forwards cubic-bezier(0, 0, 0.3, 1);

    @keyframes showProjectList {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    &__wrapper {
        position: relative;
        padding: 52px 30px 103px;
        height: 100%;

        &::after {
            content: "";
            background-image: url("/assets/backgrounds/project-list-bg.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    &__projects {
        height: 401px;
        overflow-y: auto;

        // Scrollbar
        $trackColor: #e7e3db;
        $thumbColor: #d7d2c3;

        scrollbar-width: thin;
        scrollbar-color: $thumbColor $trackColor;

        &::-webkit-scrollbar {
            width: 11px;
        }
        &::-webkit-scrollbar-track {
            background: $trackColor;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $thumbColor;
            border-radius: 6px;
            border: 3px solid $trackColor;
        }
        // End Scrollbar
    }

    &__title {
        font-size: 45px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        margin: 0;
        margin-bottom: 40px;
        color: #1b115c;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;

        svg {
            height: 100%;
        }
    }

    .project-item {
        padding: 16px 13px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
