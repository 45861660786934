.go-back-button {
    display: none;
    background-color: #f3cb47;
    border: none;
    border-radius: 5px;
    padding: 18px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 30px;

    &--active {
        display: flex;
    }

    &::before {
        content: "";
        background-image: url("/assets/icons/icon-go-back.svg");
        background-repeat: no-repeat;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 5px;
    }
}
