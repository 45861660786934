//subtitle styling
::cue {
    font-size: 2rem
}
.top-screen {
    &__logo-wrapper {
        height: 100vh;
        width: 100vw;
        position: relative;
    }

    &__logo-container {
        max-width: 800px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__media-container {
        width: 100%;
        height: 100vh;
        margin: 0;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__video-wrapper,
    &__loop-video-wrapper {
        width: 100%;
        height: 100vh;
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
