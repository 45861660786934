* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Ambit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Ambit";
  src: url("./fonts/Ambit-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TUIType";
  src: url("./fonts/TUIType.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
